import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const GitHubPage = () => (
  <Layout>
    <Seo title="GitLab CI/CD Demo" />
    <h1>GitLab CI/CD Demo</h1>
    <p>Repositories: <a href="https://gitlab.com/vnsp">GitLab</a>.</p>
    <p>
      <h3>Building, testing and deployment </h3>
      <ul>
        <li><strong>GitHub Pages Demo</strong><br />
            Create a repository <br />
            Create a new public repository named subdomain.github.io, where username is organization name on GitHub.<br />
            Publish to Git HubPages: <a href="https://vnspnet.github.io">vnspnet.github.io</a>.
        </li>
        <li>GitLab CI/CD <br />
            GitLab uses its built-in tool called GitLab CI/CD to build your site and publish it to the GitLab Pages server: <a href="https://vnsp.gitlab.io">vnsp.gitlab.io</a>.</li>
        <li>Netlify Build is modern CI/CD infrastructure for frontend teams, pre-configured and fully automated: <a href="https://vnsp.netlify.app">vnsp.netlify.app</a>.</li>
        <li>Deploy to <a href="https://vnsp.gatsbyjs.io">vnsp.gatsbyjs.io</a> and <a href="https://vnsp.gtsb.io">vnsp.gtsb.io</a>.</li>
        <li>Cloudflare Pages is a JAMstack platform for frontend developers to collaborate and deploy websites <a href="https://vnsp.pages.dev">vnsp.pages.dev</a>.</li>
        <li>Next.js by Vercel to build static and dynamic websites <a href="https://vnsp.vercel.app">vnsp.vercel.app</a>.</li>
        <li>Firebase Realtime Database accelerate app development with fully managed backend infrastructure <a href="https://vnsp-net.web.app">vnsp-net.web.app</a>.</li>
        <li>Testing app <a href="https://vnsp.herokuapp.com">vnsp.herokuapp.com</a>.</li>
        <li>Build on <a href="https://vnsp.onrender.com">Render Cloud</a>.</li>
      </ul>
    </p>
  </Layout>
)

export default GitHubPage
